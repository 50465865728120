<script>
import CategoryNewView from './CategoryNewView'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'CategoryEditView',
  extends: CategoryNewView,
  methods: {
    getCategory () {
      this.$store.dispatch('category/fetchOne', this.$route.params.id)
        .then(() => {
          this.category = this.$store.getters['category/detail']
        })
    },
    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) {
        NotifyService.setErrorMessage(this.$t('notify.please_fill_all_required_fields'))
        return
      }
      this.$store.dispatch('category/update', this.category)
        .then(() => {
          this.category = this.$store.getters['category/detail']
          if (this.$store.getters['category/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_updated'))
          } else {
            NotifyService.setErrorMessage(this.$store.getters['category/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  created () {
    this.getCategory()
  }
}
</script>
